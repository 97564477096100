import { AlertType } from '@/constants';
import { FormRules } from '@/utils/form';
import { User } from '@/models/orm/Hierarchy';

const TimeoutingComponent = () => import('@/components/ui/TimeoutingComponent.vue');

export default {
  name: 'UserEdit',

  props: {
    user: {
      type: User,
      required: true,
    },
  },

  components: {
    TimeoutingComponent,
  },

  data() {
    return {
      fieldValues: {
        firstName: null,
        lastName: null,
        displayName: null,
        genderID: null,
      },
      valid: true,
      sending: false,
      messageText: null,
      messageType: null,
    };
  },

  computed: {
    generalFields() {
      return [
        {
          label: '$vuetify.firstNameLabel',
          key: 'firstName',
          rules: [FormRules.required],
        },
        {
          label: '$vuetify.lastNameLabel',
          key: 'lastName',
          rules: [FormRules.required],
        },
        {
          label: '$vuetify.displayNameLabel',
          key: 'displayName',
          rules: [FormRules.required],
        },
      ];
    },
  },


  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.messageText = null;
        this.messageType = null;

        this.sending = true;
        const updateProfileResult = await this.updateProfile();
        this.sending = false;
        this.messageText =
          updateProfileResult.type === AlertType.success
            ? this.$vuetify.lang.t('$vuetify.userProfile.successMessage')
            : updateProfileResult.message;
        this.messageType = updateProfileResult.type;
      }
    },
    async updateProfile() {
      let payload = {
        firstName: this.fieldValues.firstName,
        lastName: this.fieldValues.lastName,
        displayName: this.fieldValues.displayName,
        genderID: this.fieldValues.genderID,
      };

      const updateProfileResult = await this.$store.dispatch('userProfile/updateProfile', payload);

      return updateProfileResult;
    },

    updateFields(val) {
      if (val) {
        this.fieldValues.firstName = this.user.Firstname;
        this.fieldValues.lastName = this.user.Lastname;
        this.fieldValues.displayName = this.user.DisplayName;
        this.fieldValues.genderID = this.user.GenderID;
      }
    }
  },

  watch: {
    user: {
      handler: 'updateFields',
      immediate: true,
    },
  },
};
